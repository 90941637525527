<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    data: Array
  },
  data() {
    return {
      myChart: null,
      dataSource: this.data
    }
  },
  watch: {
    data: {
      deep: true, // 深度监听
      handler: function (newVal) {
        this.dataSource = newVal
        this.myChart.setOption({
          dataset: [
            {
              dimensions: ['mon', 'inweight'],
              source: this.dataSource.map((item) => {
                return Object.assign({}, { mon: item.mon, inweight: item.inweight })
              })
            },
            {
              dimensions: ['mon', 'outweight'],
              source: this.dataSource.map((item) => {
                return Object.assign({}, { mon: item.mon, outweight: item.outweight })
              })
            }
          ]
        })
      }
    }
  },
  mounted() {
    this.initChart()
  },
  /*  beforeDestroy() {
    if (!this.myChart) {
      return
    }
    this.myChart.dispose()
    this.myChart = null
  },*/
  methods: {
    initChart() {
      this.myChart = echarts.init(document.getElementById(this.id))
      let option = {
        title: {
          textStyle: {
            fontWeight: 'normal',
            fontSize: 16,
            color: '#F1F1F3'
          },
          left: '1%'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#57617B'
            }
          }
        },
        legend: {
          top: 20,
          icon: 'rect',
          itemWidth: 14,
          itemHeight: 5,
          itemGap: 13,
          data: ['成本价', '销售额'],
          right: '4%',
          textStyle: {
            fontSize: 10,
            color: '#F1F1F3'
          }
        },
        grid: {
          left: '2%',
          right: '6%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: '#57617B'
              }
            }
            // data: ['13:00', '13:05', '13:10', '13:15', '13:20', '13:25', '13:30', '13:35', '13:40', '13:45', '13:50', '13:55']
          }
        ],
        yAxis: [
          {
            type: 'value',
            /*  name: '(条)',*/
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#57617B'
              }
            },
            axisLabel: {
              margin: 10,
              textStyle: {
                fontSize: 14
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: '#57617B'
              }
            }
          }
        ],
        dataset: [
          /* dimensions: ['jcsj', 'nopfl', 'nosc', 'nozs', 'so2pfl', 'so2sc', 'so2zs', 'fqpfl', 'ycpfl', 'ycsc', 'yczs', 'yhljz'],
           source: this.lineData*/
          {
            dimensions: ['mon', 'inweight'],
            source: this.dataSource.map((item) => {
              return Object.assign({}, { mon: item.mon, inweight: item.inweight })
            })
          },
          {
            dimensions: ['mon', 'outweight'],
            source: this.dataSource.map((item) => {
              return Object.assign({}, { mon: item.mon, outweight: item.outweight })
            })
          }
        ],
        series: [
          {
            name: '成本价',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 1
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(137, 189, 27, 0.3)'
                    },
                    {
                      offset: 0.8,
                      color: 'rgba(137, 189, 27, 0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: 'rgb(137,189,27)',
                borderColor: 'rgba(137,189,2,0.27)',
                borderWidth: 12
              }
            },
            datasetIndex: 0
          },
          {
            name: '销售额',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 1
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(0, 136, 212, 0.3)'
                    },
                    {
                      offset: 0.8,
                      color: 'rgba(0, 136, 212, 0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: 'rgb(0,136,212)',
                borderColor: 'rgba(0,136,212,0.2)',
                borderWidth: 12
              }
            },
            datasetIndex: 1
          }
        ]
      }
      /* if (this.lineData.length > 7) { // 当x轴数据超过7个的时候 x轴刻度显示
        option.xAxis[0].axisLabel.interval = 3; // x轴刻度隔3个显示
        option.xAxis[0].axisLabel.showMaxLabel = false;
      }*/
      this.myChart.setOption(option)
    }
  }
}
</script>
